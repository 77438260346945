<template>
	<div class="content">
		<div class="info">
			<div class="icon">
				<i class="bi bi-caret-right-fill"></i>
			</div>
			<div class="text">
				На iPhone наблюдается баг, который корпорация Apple не исправляет в
				течение последних лет.
			</div>
		</div>
		<div class="info">
			<div class="icon">
				<i class="bi bi-caret-right-fill"></i>
			</div>
			<div class="text">
				Данная ошибка не поддается исправлению как со стороны Actid Cards, так и
				со стороны других провайдеров электронных карт.
			</div>
		</div>
		<h2>Объяснение ошибки</h2>
		На iPhone карту можно открыть двумя способами:
		<ul>
			<li>
				Через быстрый доступ <b>двойным нажатием</b> на кнопку блокировки или
				кнопку Home
			</li>
			<li>Через <b>приложение Wallet</b></li>
		</ul>
		<b>В первом случае</b> нажатие на телефон, адрес или социальные сети не
		работает.
		<br />
		<b>Во втором случае</b> всё работает.
		<br />
		<br />
		На Android-смартфонах ссылки работают.
	</div>
</template>

<style lang="scss" scoped>
	.content {
		padding-bottom: 20px;
	}
	.info {
		background: #eee;
		border-radius: 8px;
		position: relative;
		margin: 20px 0;
		.icon {
			position: absolute;
			top: 10px;
			left: 9px;
			color: #cfcfcf;
			font-size: 12px;
		}
		.text {
			position: relative;
			margin-left: 20px;
			padding: 10px 5px;
			font-size: 10pt;
			font-weight: 800;
		}
	}
	h2 {
		font-size: 16pt;
	}
	.bi-three-dots {
		border-radius: 100px;
		border: 2px solid black;
		overflow: hidden;
		height: 15px;
		width: 15px;
		font-size: 15px;
		line-height: 17px;
		display: inline-block;
		text-align: center;
		margin-bottom: -4px;
	}
	img {
		width: 100%;
		border: 2px solid #eee;
		border-radius: 10px;
		max-width: 400px;
	}
</style>