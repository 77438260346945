<template>
	<div class="content">
		<div class="info">
			<div class="icon">
				<i class="bi bi-caret-right-fill"></i>
			</div>
			<div class="text">
				Данная проблема связана с синхронизацией терминала и сервера поставщика
				кассы.
			</div>
		</div>

		<b>Проверьте наличие интернета.</b>
		<br />
		Если соединение присутсвует, то необходимо связаться с компанией,
		обслуживающей POS-терминал.
		<br />
		<br />

		Данная проблема может решаться только владельцем заведения.
		<br />
		Ниже представлен план действий.
		<br />

		<h2>Что делать?</h2>
		Обратитесь к интегратору POS-терминала в заведении, чтобы получить
		информацию, как правильно настроить или выполнить синхронизацию.

		<ul>
			<li>Poster: напишите им в личном кабинете в чат.</li>
			<li>
				IIKO: напишите или позвоните интегратору, обслущивающему ваше заведение.
			</li>
			<li>
				RKeeper: напишите или позвоните интегратору, обслущивающему ваше
				заведение.
			</li>
		</ul>

		<h2>Что писать?</h2>
		<quote>
			Здравствуйте, у нас в заведении не синхронизируется терминал с сервером.
			<br />
			Количество бонусов гостя на терминале отличается от количества бонусов в
			системе.
			<br />
			Подскажите, как синхронизировать систему вручную и как её настроить, чтобы
			синхронизация была постоянной.
		</quote>
	</div>
</template>

<script>
	import VueQRCodeComponent from "vue-qr-generator";
	export default {
		components: {
			QRCODE: VueQRCodeComponent,
		},
		data() {
			return {
				shop_path: "",
			};
		},
		mounted() {
			this.shop_path = this.$route.query.sp;
		},
	};
</script>

<style lang="scss" scoped>
	.content {
		padding-bottom: 20px;
	}
	.info {
		background: #eee;
		border-radius: 8px;
		position: relative;
		margin: 20px 0;
		.icon {
			position: absolute;
			top: 10px;
			left: 9px;
			color: #cfcfcf;
			font-size: 12px;
		}
		.text {
			position: relative;
			margin-left: 20px;
			padding: 10px 5px;
			font-size: 10pt;
			font-weight: 800;
		}
	}
	h2 {
		font-size: 16pt;
	}
	.bi-three-dots {
		border-radius: 100px;
		border: 2px solid black;
		overflow: hidden;
		height: 15px;
		width: 15px;
		font-size: 15px;
		line-height: 17px;
		display: inline-block;
		text-align: center;
		margin-bottom: -4px;
	}
	img {
		width: 100%;
		border: 2px solid #eee;
		border-radius: 10px;
		max-width: 400px;
		&.badge {
			width: 150px;
			border: none;
		}
	}
	.infoIcon {
		border: 2px solid;
		border-radius: 100px;
		overflow: hidden;
		height: 16px;
		width: 16px;
		display: inline-block;
		font-weight: bold;
		text-align: center;
		font-size: 12px;
		margin-bottom: -5px;
	}
	quote {
		border-left: 2px solid #eee;
		display: block;
		padding-left: 10px;
	}
</style>

<style lang="scss">
	.qrCode {
		text-align: center;
		img {
			margin: 0 auto;
			border: 6px solid #eee;
			border-radius: 8px;
		}
	}
</style>