
<template>
	<div class="loading_holder">
		<div class="dot-falling"></div>
	</div>
</template>

<style lang="scss" scoped>
	// https://nzbin.github.io/three-dots/

	.loading_holder {
		text-align: left;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	$main-color: #d1d1d1;

	.dot-falling {
		position: relative;
		left: -9999px;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: $main-color;
		color: $main-color;
		box-shadow: 9999px 0 0 0 $main-color;
		-webkit-animation: dot-falling 1s infinite linear;
		animation: dot-falling 1s infinite linear;
		-webkit-animation-delay: 0.1s;
		animation-delay: 0.1s;
	}

	.dot-falling::before,
	.dot-falling::after {
		content: "";
		display: inline-block;
		position: absolute;
		top: 0;
	}

	.dot-falling::before {
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: $main-color;
		color: $main-color;
		-webkit-animation: dot-falling-before 1s infinite linear;
		animation: dot-falling-before 1s infinite linear;
		-webkit-animation-delay: 0s;
		animation-delay: 0s;
	}

	.dot-falling::after {
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: $main-color;
		color: $main-color;
		-webkit-animation: dot-falling-after 1s infinite linear;
		animation: dot-falling-after 1s infinite linear;
		-webkit-animation-delay: 0.2s;
		animation-delay: 0.2s;
	}

	@-webkit-keyframes dot-falling {
		0% {
			box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
		}
		25%,
		50%,
		75% {
			box-shadow: 9999px 0 0 0 $main-color;
		}
		100% {
			box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
		}
	}

	@keyframes dot-falling {
		0% {
			box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
		}
		25%,
		50%,
		75% {
			box-shadow: 9999px 0 0 0 $main-color;
		}
		100% {
			box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
		}
	}

	@-webkit-keyframes dot-falling-before {
		0% {
			box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
		}
		25%,
		50%,
		75% {
			box-shadow: 9984px 0 0 0 $main-color;
		}
		100% {
			box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
		}
	}

	@keyframes dot-falling-before {
		0% {
			box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
		}
		25%,
		50%,
		75% {
			box-shadow: 9984px 0 0 0 $main-color;
		}
		100% {
			box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
		}
	}

	@-webkit-keyframes dot-falling-after {
		0% {
			box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
		}
		25%,
		50%,
		75% {
			box-shadow: 10014px 0 0 0 $main-color;
		}
		100% {
			box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
		}
	}

	@keyframes dot-falling-after {
		0% {
			box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
		}
		25%,
		50%,
		75% {
			box-shadow: 10014px 0 0 0 $main-color;
		}
		100% {
			box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
		}
	}
</style>