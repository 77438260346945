<template>
	<div class="content">
		<h2>iOS</h2>
		<ul>
			<li>Откройте камеру устройства</li>
			<li>Наведите устройство на QR-код</li>
			<li>У вас отобразится ссылка, передаваемая в QR-коде</li>
			<li>Нажмите на ссылку</li>
		</ul>

		<h2>Android</h2>
		В зависимости от производителя смартфона - отличается способ работы с
		QR-кодом.
		<h2>Android, камера</h2>
		<ul>
			<li>Откройте камеру устройства</li>
			<li>Наведите устройство на QR-код</li>
			<li>Ссылка может открыться сразу же или...</li>
			<li>... у вас отобразится ссылка, передаваемая в QR-коде</li>
			<li>Нажмите на ссылку</li>
		</ul>
		Если Android смартфон никак не реагирует на QR-код, попробуйте следующий
		метод.

		<h2>Android, приложение</h2>
		Найдите и скачайте в магазине приложений любое приложение, которое
		специализаруется на сканировании QR-кодов.
		<ul>
			<li>Откройте маркетплейс, например, Google Play</li>
			<li>Напишите в поиске "QR Scanner"</li>
			<li>
				Установите любое приложение. Желательно выберите приложение с наибольшим
				количеством установок (100 млн+)
			</li>
			<li>
				Воспользуйтесь выбранным приложением, наведите смартфон на QR-код.
			</li>
		</ul>
	</div>
</template>

<script>
	import VueQRCodeComponent from "vue-qr-generator";
	export default {
		components: {
			QRCODE: VueQRCodeComponent,
		},
		data() {
			return {
				shop_path: "",
			};
		},
		mounted() {
			this.shop_path = this.$route.query.sp;
		},
	};
</script>

<style lang="scss" scoped>
	.content {
		padding-bottom: 20px;
	}
	.info {
		background: #eee;
		border-radius: 8px;
		position: relative;
		margin: 20px 0;
		.icon {
			position: absolute;
			top: 10px;
			left: 9px;
			color: #cfcfcf;
			font-size: 12px;
		}
		.text {
			position: relative;
			margin-left: 20px;
			padding: 10px 5px;
			font-size: 10pt;
			font-weight: 800;
		}
	}
	h2 {
		font-size: 16pt;
	}
	.bi-three-dots {
		border-radius: 100px;
		border: 2px solid black;
		overflow: hidden;
		height: 15px;
		width: 15px;
		font-size: 15px;
		line-height: 17px;
		display: inline-block;
		text-align: center;
		margin-bottom: -4px;
	}
	img {
		width: 100%;
		border: 2px solid #eee;
		border-radius: 10px;
		max-width: 400px;
		&.badge {
			width: 150px;
			border: none;
		}
	}
	.infoIcon {
		border: 2px solid;
		border-radius: 100px;
		overflow: hidden;
		height: 16px;
		width: 16px;
		display: inline-block;
		font-weight: bold;
		text-align: center;
		font-size: 12px;
		margin-bottom: -5px;
	}
</style>

<style lang="scss">
	.qrCode {
		text-align: center;
		img {
			margin: 0 auto;
			border: 6px solid #eee;
			border-radius: 8px;
		}
	}
</style>