<template>
	<div class="content">
		<div style="margin-top: 20px"></div>

		<h2>Ошибка<br />"Похоже, этой страницы не существует!"</h2>
		<ul>
			<li v-if="shop_path">
				Убедитесь, что открытой страницей является
				<a :href="`https://wallet.actid.ru/${shop_path}/`" target="_blank"
					>https://wallet.actid.ru/{{ shop_path }}/</a
				>
			</li>
			<li v-else>
				Убедитесь, что ссылка открытой страницы соответствует формату:
				<br />
				https://wallet.actid.ru/<b>КОД</b>/
			</li>
			<li>
				В случае если адрес страницы введен верно, необходимо обратиться в
				службу поддержки. <b>Ссылки указаны ниже.</b>
			</li>
		</ul>

		<h2>Пустая страница с текстом<br />"not found!"</h2>
		<ul>
			<template v-if="shop_path">
				<li>
					В случае, если открыта страница <b>sl.actid.ru</b>, перейдите по
					адресу
					<a :href="`https://wallet.actid.ru/${shop_path}/`" target="_blank"
						>https://wallet.actid.ru/{{ shop_path }}/</a
					>
				</li>
				<li>
					Для быстрого доступа к странице с устройства гостя, предложите ему
					перейти по QR-коду:
				</li>
			</template>
			<template v-else>
				<li>
					Попробуйте перейти по прямой ссылке на страницу регистрации карты.
				</li>
				<li>Формат страницы: https://wallet.actid.ru/<b>КОД</b>/</li>
			</template>
		</ul>

		<QRCODE
			class="qrCode"
			:text="`https://wallet.actid.ru/${shop_path}/`"
			v-if="shop_path"
		></QRCODE>
		<ul>
			<li>
				В случае если ошибка сохранится, необходимо обратиться в службу
				поддержки. <b>Ссылки указаны ниже.</b>
			</li>
		</ul>

		<h2>Ошибка<br />"Отсутсвует подключение к интернету!"</h2>
		Данная пролема связана с устройством гостя и с его доступом в интернет.
		Попрубуйте подключиться к другой сети.
		<br />
		<ul>
			<li>Если смартфон работает от Wi-Fi, подключитесь к сотовой сети.</li>
			<li>Если смартфон работает от сотовой сети, подключитесь к Wi-Fi.</li>
		</ul>

		<h2>Ошибка<br />"Подключение не защищено"</h2>
		<ul>
			<li>
				Мы не советуем регистрироваться на странице, подключение которой не
				защищено.
			</li>
			<li>
				Пожалуйста, обратитесь в службу поддержки, чтобы мы расследовали
				инцидент. <b>Ссылки указаны ниже.</b>
			</li>
		</ul>

		Служба поддержки:
		<ul>
			<li>Email: <a href="mailto:dev@actid.ru">dev@actid.ru</a></li>
			<li>Telegram: <a href="https://t.me/actid_company">@actid_company</a></li>
		</ul>
		Обязательно укажите ошибку и название заведения, в котором произошел сбой.
		<br />
		По возможности, добавьте скриншот или видеозапись.
	</div>
</template>

<script>
	import VueQRCodeComponent from "vue-qr-generator";
	export default {
		components: {
			QRCODE: VueQRCodeComponent,
		},
		data() {
			return {
				shop_path: "",
			};
		},
		mounted() {
			this.shop_path = this.$route.query.sp;
		},
	};
</script>

<style lang="scss" scoped>
	.content {
		padding-bottom: 20px;
	}
	.info {
		background: #eee;
		border-radius: 8px;
		position: relative;
		margin: 20px 0;
		.icon {
			position: absolute;
			top: 10px;
			left: 9px;
			color: #cfcfcf;
			font-size: 12px;
		}
		.text {
			position: relative;
			margin-left: 20px;
			padding: 10px 5px;
			font-size: 10pt;
			font-weight: 800;
		}
	}
	h2 {
		font-size: 16pt;
	}
	.bi-three-dots {
		border-radius: 100px;
		border: 2px solid black;
		overflow: hidden;
		height: 15px;
		width: 15px;
		font-size: 15px;
		line-height: 17px;
		display: inline-block;
		text-align: center;
		margin-bottom: -4px;
	}
	img {
		width: 100%;
		border: 2px solid #eee;
		border-radius: 10px;
		max-width: 400px;
		&.badge {
			width: 150px;
			border: none;
		}
	}
	.infoIcon {
		border: 2px solid;
		border-radius: 100px;
		overflow: hidden;
		height: 16px;
		width: 16px;
		display: inline-block;
		font-weight: bold;
		text-align: center;
		font-size: 12px;
		margin-bottom: -5px;
	}
</style>

<style lang="scss">
	.qrCode {
		text-align: center;
		img {
			margin: 0 auto;
			border: 6px solid #eee;
			border-radius: 8px;
		}
	}
</style>