<template>
	<router-view />
</template>

<script>
</script>

<style lang="scss">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		// margin-bottom: 40px;
	}
	body {
		margin: 0;
	}
	// no scroll class
	.no-scroll {
		overflow: hidden;
	}
</style>
