<template>
	<div class="content">
		<div class="info">
			<div class="icon">
				<i class="bi bi-caret-right-fill"></i>
			</div>
			<div class="text">
				Успокойте гостя.
				<br />
				Что бы то не было, его бонусы и скидки учитываются в системе в любом
				случае.
			</div>
		</div>
		<div class="info">
			<div class="icon">
				<i class="bi bi-caret-right-fill"></i>
			</div>
			<div class="text">
				Карта - это просто визуальное отображение аккаунта гостя, которое иногда
				может обновляться несовсем вовремя.
			</div>
		</div>
		<h2>Проверьте автоматическое обновление</h2>
		<ul>
			<li>Откройте карту</li>
			<li>
				Перейдите на "Заднюю" сторону карты, нажав
				<i class="bi bi-three-dots"></i>
			</li>
			<li>
				Проверьте переключатель "Автоматическое обновление", он должен быть
				включен
			</li>
		</ul>
		<img src="/img/screen/autoupdate_ios.png" />
		<h2>Попробуйте обновить вручную</h2>
		<ul>
			<li>Откройте карту</li>
			<li>
				Перейдите на "Заднюю" сторону карты, нажав
				<i class="bi bi-three-dots"></i>
			</li>
			<li>Свайпните экран вниз, чтобы вызвать обновление карты вручную</li>
		</ul>
		<h2>Проверьте подключение к интернету</h2>
		Устройство может быть неподключено к сети, из-за чего не может скачать
		обновление карты.
		<br />
		Попробуйте обновить карту как от мобильной сети, так и от Wi-Fi.
		<h2>Убедитесь, что терминал синхронизирован</h2>
		Иногда бывает, что терминал рассинхронизирован со своим сервером, из-за это
		данные на карте и на терминале могут отличаться.
		<h2>Гость называет не ту карту</h2>
		Гость по ошибке мог назвать не свою карту или не свой номер телефона.
		<br />
		При присоединении карты к заказу, уточняйте Имя гостя, чтобы удостовериться,
		что выбрана верная карта.
		<br />
		Если будет привязана чужая карта, то гость не получит бонусов (карта не
		обновится) или не сможет воспользоваться скидкой.
	</div>
</template>

<style lang="scss" scoped>
	.content {
		padding-bottom: 20px;
	}
	.info {
		background: #eee;
		border-radius: 8px;
		position: relative;
		margin: 20px 0;
		.icon {
			position: absolute;
			top: 10px;
			left: 9px;
			color: #cfcfcf;
			font-size: 12px;
		}
		.text {
			position: relative;
			margin-left: 20px;
			padding: 10px 5px;
			font-size: 10pt;
			font-weight: 800;
		}
	}
	h2 {
		font-size: 16pt;
	}
	.bi-three-dots {
		border-radius: 100px;
		border: 2px solid black;
		overflow: hidden;
		height: 15px;
		width: 15px;
		font-size: 15px;
		line-height: 17px;
		display: inline-block;
		text-align: center;
		margin-bottom: -4px;
	}
	img {
		width: 100%;
		border: 2px solid #eee;
		border-radius: 10px;
		max-width: 400px;
	}
</style>