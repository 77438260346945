<template>
	<div class="content">
		<div class="info">
			<div class="icon">
				<i class="bi bi-caret-right-fill"></i>
			</div>
			<div class="text">
				Успокойте гостя.
				<br />
				Что бы то не было, его бонусы и скидки учитываются в системе в любом
				случае.
			</div>
		</div>
		<h2>Проверьте доступ</h2>
		<ul>
			<li>Разблокируйте телефон</li>
			<li>Найдите и откройте <b>приложение Wallet</b></li>
			<li>Откройте карту</li>
			<li>
				Перейдите на "Заднюю" сторону карты, нажав
				<i class="bi bi-three-dots"></i>
			</li>
			<li>
				Проверьте переключатель "На заблокированном экране", он должен быть
				включен
			</li>
		</ul>
		<img src="/img/screen/lockscreen_ios.png" />
		<h2>Карты нет в приложении Wallet</h2>
		Попробуйте перевыпустить карту.
		<br />
		Все бонусы и скидки сохранятся.
		<ul>
			<li>
				<template v-if="!!shop_path">
					Перейдите по ссылке
					<a :href="`https://wallet.actid.ru/${shop_path}/`" target="_blank"
						>https://wallet.actid.ru/{{ shop_path }}/</a
					>
					<br />Или предоставьте гостю QR-код для быстрого перехода:
				</template>
				<template v-else>
					Перейдите по ссылке получения карты, например, с тейблтента.
				</template>
			</li>
		</ul>
		<QRCODE
			class="qrCode"
			:text="`https://wallet.actid.ru/${shop_path}/`"
			v-if="!!shop_path"
		></QRCODE>
		<ul>
			<li>Заполните и получите карту.</li>
		</ul>
		<div class="info">
			<div class="icon">
				<i class="bi bi-caret-right-fill"></i>
			</div>
			<div class="text">
				Проследите, чтобы гость не просто закрыл карту, а нажал
				<b>"Добавить"</b>.
			</div>
		</div>
	</div>
</template>

<script>
	import VueQRCodeComponent from "vue-qr-generator";
	export default {
		components: {
			QRCODE: VueQRCodeComponent,
		},
		data() {
			return {
				shop_path: "",
			};
		},
		mounted() {
			this.shop_path = this.$route.query.sp;
		},
	};
</script>

<style lang="scss" scoped>
	.content {
		padding-bottom: 20px;
	}
	.info {
		background: #eee;
		border-radius: 8px;
		position: relative;
		margin: 20px 0;
		.icon {
			position: absolute;
			top: 10px;
			left: 9px;
			color: #cfcfcf;
			font-size: 12px;
		}
		.text {
			position: relative;
			margin-left: 20px;
			padding: 10px 5px;
			font-size: 10pt;
			font-weight: 800;
		}
	}
	h2 {
		font-size: 16pt;
	}
	.bi-three-dots {
		border-radius: 100px;
		border: 2px solid black;
		overflow: hidden;
		height: 15px;
		width: 15px;
		font-size: 15px;
		line-height: 17px;
		display: inline-block;
		text-align: center;
		margin-bottom: -4px;
	}
	img {
		width: 100%;
		border: 2px solid #eee;
		border-radius: 10px;
		max-width: 400px;
	}
</style>

<style lang="scss">
	.qrCode {
		text-align: center;
		img {
			margin: 0 auto;
			border: 6px solid #eee;
			border-radius: 8px;
		}
	}
</style>