<template>
	<div class="content">
		<div class="info">
			<div class="icon">
				<i class="bi bi-caret-right-fill"></i>
			</div>
			<div class="text">
				Чтобы обновить какую-либо информацию на карте, необходимо перевыпустить
				карту.
			</div>
		</div>
		<div class="info">
			<div class="icon">
				<i class="bi bi-caret-right-fill"></i>
			</div>
			<div class="text">
				При перевыпуске карты все бонусы и скидки гостя сохраняются.
			</div>
		</div>
		<h2>Перевыпуск карты</h2>
		<h2>Android</h2>
		<ul>
			<li>
				Найдите <b>приложение Карты (WalletPasses)</b> и перейдите в него.
			</li>
			<li>
				Откройте карту заведения и нажмите на
				<span class="infoIcon">i</span> справа снизу карты.
			</li>
			<li>Слева сверху найдите кнопку "Удалить". Удалите карту.</li>
			<li>
				<template v-if="!!shop_path">
					Перейдите по ссылке
					<a :href="`https://wallet.actid.ru/${shop_path}/`" target="_blank"
						>https://wallet.actid.ru/{{ shop_path }}/</a
					>
					<br />Или предоставьте гостю QR-код для быстрого перехода:
				</template>
				<template v-else>
					Перейдите по ссылке получения карты, например, с тейблтента.
				</template>
			</li>
		</ul>
		<QRCODE
			class="qrCode"
			:text="`https://wallet.actid.ru/${shop_path}/`"
			v-if="!!shop_path"
		></QRCODE>
		<ul>
			<li>Заполните все поля.</li>
			<li>
				Когда будет предложено установить карту, нажмите на
				<img class="badge" src="/img/badges/android_badge.png" alt="" />
			</li>
			<li>
				Если смартфон предложит Вам выбрать приложение для открытия карты,
				выберите WalletPasses.
			</li>
			<li>Карта добавлена!</li>
		</ul>

		<h2>iOS</h2>
		<ul>
			<li>Найдите <b>приложение Wallet</b> и перейдите в него.</li>
			<li>
				Откройте карту заведения и нажмите на
				<i class="bi bi-three-dots"></i> справа сверху экрана.
			</li>
			<li>Найдите кнопку "Удалить карту". Удалите карту.</li>
		</ul>
		<img src="/img/screen/remove_card_ios.png" />
		<ul>
			<li>
				<template v-if="!!shop_path">
					Перейдите по ссылке
					<a :href="`https://wallet.actid.ru/${shop_path}/`" target="_blank"
						>https://wallet.actid.ru/{{ shop_path }}/</a
					>
					<br />Или предоставьте гостю QR-код для быстрого перехода:
				</template>
				<template v-else>
					Перейдите по ссылке получения карты, например, с тейблтента.
				</template>
			</li>
		</ul>
		<QRCODE
			class="qrCode"
			:text="`https://wallet.actid.ru/${shop_path}/`"
			v-if="!!shop_path"
		></QRCODE>
		<ul>
			<li>Заполните все поля.</li>
			<li>
				Когда будет предложено установить карту, нажмите на
				<img class="badge" src="/img/badges/ios_badge.svg" alt="" />
			</li>
			<li>Карта добавлена!</li>
		</ul>
		<div class="info">
			<div class="icon">
				<i class="bi bi-caret-right-fill"></i>
			</div>
			<div class="text">
				Проследите, чтобы гость не просто закрыл карту, а нажал
				<b>"Добавить"</b>.
			</div>
		</div>
	</div>
</template>

<script>
	import VueQRCodeComponent from "vue-qr-generator";
	export default {
		components: {
			QRCODE: VueQRCodeComponent,
		},
		data() {
			return {
				shop_path: "",
			};
		},
		mounted() {
			this.shop_path = this.$route.query.sp;
		},
	};
</script>

<style lang="scss" scoped>
	.content {
		padding-bottom: 20px;
	}
	.info {
		background: #eee;
		border-radius: 8px;
		position: relative;
		margin: 20px 0;
		.icon {
			position: absolute;
			top: 10px;
			left: 9px;
			color: #cfcfcf;
			font-size: 12px;
		}
		.text {
			position: relative;
			margin-left: 20px;
			padding: 10px 5px;
			font-size: 10pt;
			font-weight: 800;
		}
	}
	h2 {
		font-size: 16pt;
	}
	.bi-three-dots {
		border-radius: 100px;
		border: 2px solid black;
		overflow: hidden;
		height: 15px;
		width: 15px;
		font-size: 15px;
		line-height: 17px;
		display: inline-block;
		text-align: center;
		margin-bottom: -4px;
	}
	img {
		width: 100%;
		border: 2px solid #eee;
		border-radius: 10px;
		max-width: 400px;
		&.badge {
			width: 150px;
			border: none;
		}
	}
	.infoIcon {
		border: 2px solid;
		border-radius: 100px;
		overflow: hidden;
		height: 16px;
		width: 16px;
		display: inline-block;
		font-weight: bold;
		text-align: center;
		font-size: 12px;
		margin-bottom: -5px;
	}
</style>

<style lang="scss">
	.qrCode {
		text-align: center;
		img {
			margin: 0 auto;
			border: 6px solid #eee;
			border-radius: 8px;
		}
	}
</style>